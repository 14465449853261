{
    "analytics": {
        "anonymization": {
            "anonymization_disabled": "Behavioral data collected about your users is not anonymized.",
            "anonymization_enabled": "Behavioral data collected about your users is anonymized.",
            "anonymization_selected_users": "Behavioral data about selected users is anonymized.",
            "anoynmization_domains": "Email domains anonymized:"
        },
        "charts": {
            "activations": {
                "title": "Activations"
            },
            "active_users": {
                "activated": "Activated",
                "active_last_hour": "Active in the last hour",
                "active_today": "Active today",
                "of_activated": "of the activated users",
                "of_total_users": "of the total users",
                "title": "Active users",
                "total_users": "Total users"
            },
            "registrations": {
                "title": "Registrations",
                "today": "Today",
                "total": "Total"
            },
            "timespans": {
                "all": "All",
                "last_day": "Last day",
                "last_week": "Last week"
            },
            "turnouts": {
                "title": "Activity rate"
            }
        },
        "exports": {
            "download": "Download XLS",
            "end": "End date",
            "failed": "Could not perform the export.",
            "fields": "Select the fields to export",
            "platforms": "Platforms",
            "preselected_fields": "First name, last name, and e-mail are always included.",
            "start": "Start date"
        },
        "metrics": {
            "accepted_rsvp": {
                "description": "Users that were personally invited to the event and have accepted.",
                "title": "RSVP accepted users"
            },
            "activated_users": {
                "description": "Users who have accessed the app at least once, who may or may not have performed an action in the app.",
                "title": "Activated users"
            },
            "active_users": {
                "description": "Users who have performed any action in the app such as viewing a feed, the agenda, a document etc.",
                "title": "Active users"
            },
            "created_users": {
                "description": "Active, pending or hidden users. Cancelled users are not shown.",
                "title": "Total users"
            },
            "mailing_count": {
                "description": "Date when an email campaign was sent. Does not include emails sent out from the registration page.",
                "title": "Emailing campaigns"
            },
            "more": "More about metrics",
            "rsvp_accepted": {
                "limits": "of capacity limit",
                "title": "RSVP accepted",
                "total": "Acceptance rate"
            },
            "rsvp_declined": {
                "title": "RSVP declined",
                "total": "Decline rate"
            },
            "rsvp_pending": {
                "title": "RSVP pending",
                "total": "No response rate"
            },
            "rsvp_users": {
                "description": "Users that were personally invited to the event and have responded/not responded yet.",
                "title": "RSVP users"
            },
            "self_registered_users": {
                "description": "Total number of users that registered via the registration page.",
                "limits": "of capacity limit",
                "title": "Self-registered users"
            },
            "self_unregistered_users": {
                "title": "Unregistered users"
            },
            "total_users": {
                "description": "Active, pending or hidden users. Cancelled users are not shown.",
                "title": "Total users"
            }
        },
        "quick_links": {
            "title": "Quick links"
        },
        "reports": {
            "activity": {
                "long_description": "Summary of the activity of the users on the platform: breakdown of created, registered, activated, and active users. User is active if an action was performed in the app.",
                "short_description": "Breakdown of all created, registered, activated, and active users on the workspace.",
                "title": "Registrations and activations"
            },
            "attendance": {
                "long_description": "Attendance tracking reports by event and by day.",
                "short_description": "Attendance tracking reports by event and by day.",
                "title": "Attendance report"
            },
            "badge-printing": {
                "long_description": "Summary of all event check ins an badges printed. Details provided at an overall and user level, included details on users that were expected but did not check in.",
                "short_description": "Breakdown of all users that checked into the event and badges printed",
                "title": "Event check in and badge printing"
            },
            "content-hub-activity": {
                "long_description": "Summary of the activity of the users on the platform: breakdown of created, registered, activated, and active users. User is active if an action .",
                "short_description": "Breakdown of user details and their activity on the hub. ",
                "title": "User details and activity"
            },
            "documents": {
                "long_description": "Summary of data for all documents: names, creation dates, total number of views and views over time. Individual reports per document, with the same data but including user information.",
                "short_description": "Breakdown of all documents with number of views and viewer user information.",
                "title": "Documents"
            },
            "feeds": {
                "long_description": "Summary of data for all feeds: names, creation dates, number of posts, posts with images/videos, number of comments and number of likes. Individual reports per feed, with the same data but including user information.",
                "short_description": "Breakdown of all feeds with number of posts, posts with images/videos/comments, including user information.",
                "title": "Feeds"
            },
            "forms": {
                "long_description": "Summary of data for all forms: titles, creation dates, number of responses per form. Individual reports per form, with the same data but including user information.",
                "short_description": "Breakdown of all forms, including user responses and information.",
                "title": "Forms"
            },
            "interactivity": {
                "long_description": "Summary of data for all user interactions: bookmarks, business card exchanges, conversations, notes and messages.",
                "short_description": "Breakdown of all user interactions: bookmarks, business card exchanges, conversations, notes and messages.",
                "title": "Interactivity"
            },
            "leads": {
                "long_description": "Summary of all leads captured at the event. Details provided at an overall and sponsor level, including the details of lead capturer, date, time and all form fields",
                "short_description": "Breakdown of leads captured overall and per sponsor with lead capturer, and all lead form details",
                "title": "Leads"
            },
            "live-session-interactions": {
                "long_description": "Individual report for the live session, with individual user information and interaction details as watch time, claps, Q&A sent and polls answered.",
                "short_description": "Breakdown of the live session, with viewers details, interactions and user information.",
                "title": "Live session"
            },
            "live-sessions": {
                "long_description": "Summary of data for all live sessions, with the type (hybrid/remote/in-person), start date, total viewers, total VOD viewers, claps, number of Q&A entries (all entries: Incoming and approved, etc.), number of polls (polls that were run). Individual reports per live session, with individual user information and interaction details.",
                "short_description": "Breakdown of all live sessions, with type, viewers, interactions, and user information.",
                "title": "Live sessions"
            },
            "meetings": {
                "long_description": "Summary of all meetings for your event, attendance statuses and meeting room insights.",
                "short_description": "Insights into all meetings, including meeting room usage",
                "title": "Meetings"
            },
            "pages": {
                "long_description": "Summary of data for all content pages: titles, creation dates, total number of views and views over time. Individual reports per page, with the same data but including user information.",
                "short_description": "Breakdown of all pages, with views and user information.",
                "title": "Content pages"
            },
            "sessions": {
                "long_description": "Summary of data for all agenda sessions (name, ID, start/end date, total views, capacity, registrations, attendance). Individual reports per agenda session, with the same data but including user information. Listed users include those who registered and those who viewed the session page (does not include targeted users).",
                "short_description": "Breakdown of all agenda sessions, with views, capacity, registrations and attendance, including user information.",
                "title": "Sessions"
            },
            "speakers": {
                "long_description": "Summary of data for all speakers: names and information, total number of views as well as views over time. Individual reports per speaker, with the same data but including user information.",
                "short_description": "Breakdown of all speakers, with views and user information.",
                "title": "Speakers"
            },
            "sponsors": {
                "long_description": "Summary of data for all sponsors: names and information, total number of views as well as views over time. Individual reports per sponsor, with the same data but including user information.",
                "short_description": "Breakdown of all sponsors, with views and user information.",
                "title": "Sponsors"
            },
            "videos": {
                "long_description": "Summary of data for all videos: titles, creation dates, total number of views over time, and average watch times. Individual reports per video with the same data but including user information.",
                "short_description": "Breakdown of all videos in the video library, with views, watch times and user information.",
                "title": "Videos"
            }
        },
        "reports_description": "Download Excel reports to gain insights into your audience and their engagement. For example, see who registered, how they interacted, and which content was most popular.",
        "reports_list": "Reports",
        "sections": {
            "contents": "Content",
            "engagements": "Management & engagement"
        }
    }
}
